import React from "react"

import { Container, SEOPage } from "../components";
import { Breadcrumb } from "site/src/components";
import { Grid2, Grid213, StyledPage } from "site/src/components/css";

function ProfileConstruction() {
  const SEO = {
    entity: "page",
    title: "Construction - Company profile",
    keywords: `company profile, construction`,
    description: `Barrow Construction (Pty) Ltd was established in 1897. The company began as a small construction company in Johannesburg, building residential houses in the early 20th century.`,
    url: `/construction-company-profile`
  }

  return (
    <Container page="content" breadcrumbs={true} breadData={{ type: "content", title: SEO.title }}>

      {/* Page meta data (title, description, etc.)*/}
      <SEOPage
        entity={SEO.entity}
        title={SEO.title}
        keywords={SEO.keywords}
        description={SEO.description}
        url={SEO.url}
      />

      <div>
        <Breadcrumb show={true} data={{ type: "content", title: SEO.title, image: "https://res.cloudinary.com/barrow/image/upload/f_auto/Barrow_page_headers-construction_desktop_a4aulz.jpg" }} />
        <StyledPage>

          <Grid213>
            <div>
              <div>
                <h5 style={{ fontWeight: 300, textTransform: "uppercase", textAlign: "right", background: "#d61f27", margin: 0, padding: "2rem", color: "white" }}>Construction</h5>
                <img alt="barrow construction" src="https://res.cloudinary.com/barrow/image/upload/v1627550286/Barrow_Main_banner_construction_desktop_y7wari.jpg" style={{ maxWidth: "100%" }} />
              </div>
            </div>
            <div>
              <h1 style={{ margin: 0, fontWeight: 300 }}>Company</h1>
              <h1 style={{ margin: 0, fontWeight: 300, color: "#d61f27" }}>Profile</h1>
            </div>
            <div>

            </div>
          </Grid213>
          <div style={{ marginTop: "3rem" }}>
            <Grid2>
              <div>
                <p>Barrow Construction was established in 1897 and boasts a proud tradition of quality workmanship spanning 5 generations.</p>
                <p>The Company is still a family business that, due its wealth of experience, can be relied on for consistently completing quality projects on time and to an agreed budget.</p>
                <p>Senior members of the company are “hands on” and are actively involved in all aspects of the business ensuring that the best interests of clients are always looked after.</p>
                <p>The Company currently has a Level 4 BBBEE certification and is 51% black owned. We are particularly pleased with the solid progress that we have made in bringing numerous staff members through the ranks to the point where they now occupy important positions in the business and the strong sense of loyalty that these individuals have shown bodes well for the future.</p>
              </div>
              <div>
                <p>Recent contracts that have been successfully completed include Park Central Apartments in Rosebank, City Lodge Hotel in Waterfall, Ellipse Apartments in Waterfall and Park Lane West Offices in Menlyn Maine.</p>
                <p>We are particularly well placed to add value to any project on the back of our development expertise and will often workshop designs with professional teams to ensure the delivery of a quality product at a cost-effective price.</p>
                <p>We believe that we are more than just a contractor and can bring meaningful benefit to any new development or refurbishment, no matter the size.</p>
              </div>
            </Grid2>
          </div>
          <hr />
          <div style={{marginTop: "3rem"}}>
            <p>Barrow Construction (Pty) Ltd Head Office can be located at the following address:</p>
            <p>Barrow Office Park, 101 Central Street, Houghton, Johannesburg</p>
            <p>Contact Number: <a href="tel:0117273600">+27 (0) 11 727 3600</a></p>
            <p>Email: <a href="mailto:info@barrow.co.za">info@barrow.co.za</a></p>
          </div>

        </StyledPage>
      </div>
    </Container>
  )
}

export default ProfileConstruction